import React from 'react'
import moment from 'moment';

export default function Footer() {
    return (
        <footer className='bg-light'>
            <div className="container py-4">
                <div className="row">
                    <div className='col-12 col-md-4 footer-logo'>
                        <a className="navbar-brand" href="/">
                            <img src="./images/brand-logo-1.svg" alt="main_logo" />
                        </a>
                    </div>
                    <div className='col-12 col-md-2 mt-4 mb-4 m-md-0 d-flex flex-column' style={{gap:'20px'}}>
                        <a className='link-theme-primary' href="/privacy-policy">Privacy Policy</a>
                        <a className='link-theme-primary' href="/terms-of-use">Terms of Use</a>
                        <a className='link-theme-primary' href="/contact">Contact Us</a>
                    </div>
                    <div className="col-md-6">
                        <p className="text-justify">
                            Benefit Specialists, LLC is a marketing company only and we are not a financial advisor, broker dealer, investment advisory firm, insurance underwriter/carrier, insurance agency or a CPA. If you set up an appointment, the person you will be speaking with is not employed by us. Further, by providing your contact information and setting up an appointment, you agree to our terms of service at: 
                            <br/><a href="/terms-of-use">Terms of Use</a></p>
                        <p className="mt-4 text-center text-md-left">
                            <svg xmlns="http://www.w3.org/2000/svg" color='red' viewBox="0 0 24 24" fill="currentColor" width={24}>
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg> support@{(window.location.hostname).replace("www.","")}
                        </p>

                    </div>
                </div>
            </div>
            <div className='bg-theme-primary text-center py-2'>
                <p className='text-white'>Copyright &#169; {moment().year()}. Benefit Specialists, LLC.  All Rights Reserved.</p>
            </div>
        </footer>
    )
}
