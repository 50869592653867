import React,{ useState, useEffect } from 'react'
import {Box, Button} from '@mui/material';
import minusExcept from '../../utils/array';
import ButtonTimeZone from '../ButtonTimeZone';
import moment from 'moment';
export default function TimerView({
    blocked, 
    timeSelected, setTimeSelect, 
    timezoneSelected, setTimezoneSelected,
    setStep, isMobile, 
    dateSelected, setDateSelect,
    appointmentCount
}) {
    let tz = {
        'Eastern Time (ET)': {
            index: 3,
            value: undefined
        },
        'Central Time (CT)': {
            index: 2,
            value: undefined
        },
        'Mountain Time (MT)': {
            index: 1,
            value: undefined
        },
        'Pacific Time (PT)': {
            index: 0,
            value: undefined
        },
    }
    if(timeSelected){
        let bd = blocked === undefined ? [] : [...blocked];
        tz['Eastern Time (ET)'].value =  bd.find( val => val === (moment('10-10-2022 '+ timeSelected).add(tz[timezoneSelected].index,'hours').subtract(tz['Eastern Time (ET)'].index,'hours').format('HH:mm')) + "," + appointmentCount)
        tz['Central Time (CT)'].value =  bd.find( val => val === (moment('10-10-2022 '+ timeSelected).add(tz[timezoneSelected].index,'hours').subtract(tz['Central Time (CT)'].index,'hours').format('HH:mm')) + "," + appointmentCount)
        tz['Mountain Time (MT)'].value = bd.find( val => val === (moment('10-10-2022 '+ timeSelected).add(tz[timezoneSelected].index,'hours').subtract(tz['Mountain Time (MT)'].index,'hours').format('HH:mm')) + "," + appointmentCount)
        tz['Pacific Time (PT)'].value =  bd.find( val => val === (moment('10-10-2022 '+ timeSelected).add(tz[timezoneSelected].index,'hours').subtract(tz['Pacific Time (PT)'].index,'hours').format('HH:mm')) + "," + appointmentCount)
    }
    console.log(tz,blocked)
    const timer = [
        "09:00,"+appointmentCount, "09:30,"+appointmentCount,
        "10:00,"+appointmentCount, "10:30,"+appointmentCount,
        "11:00,"+appointmentCount, "11:30,"+appointmentCount,
        "12:00,"+appointmentCount, "12:30,"+appointmentCount,
        "13:00,"+appointmentCount, "13:30,"+appointmentCount,
        "14:00,"+appointmentCount, "14:30,"+appointmentCount,
        "15:00,"+appointmentCount, "15:30,"+appointmentCount,
        "16:00,"+appointmentCount, "16:30,"+appointmentCount,
        "17:00,"+appointmentCount
    ]
    const [date, setDate] = useState(new Date(dateSelected))
    const [timeArray, setTimeArray] = useState(timer)
    const timerUpdate = (d) => {
        let dates = blocked === undefined ? [] : [...blocked];
        let update = [
            // "17:30,"+appointmentCount,
            // "18:00,"+appointmentCount, "18:30,"+appointmentCount,
            // "19:00,"+appointmentCount,
        ]
        let ta = []
        if(d.getDay() === 5){
            ta = [...timer]
        } else {
            ta = [...timer, ...update]
        }
        // console.log("comapare",ta,dates)

        ta = minusExcept(ta,dates)
        setTimeArray(ta)
    }

    useEffect(()=>{
        let date = new Date(dateSelected)
        setDate(date)
        timerUpdate(date)
    }, [dateSelected])

    return (
        <Box sx={{
            overflow: "hidden",
            overflowY: "auto",
            height: "375px",
            width:'100%',
            px:3
        }}>
            {
                timeArray.map(t=>{
                    t = t.split(',')[0]
                    return (
                        <>
                            <Box sx={{display:"flex", justifyContent:"center", gap:1, alignItems:'center'}}>
                                <Button fullWidth color="error" variant="outlined" sx={{mt:1}} onClick={()=>{setTimeSelect(t)}}>
                                    {t}
                                </Button>
                                {
                                    t === timeSelected &&
                                    <Button fullWidth color="error" variant="contained" sx={{mt:1}} onClick={()=>{setStep(2);}}>
                                        Confirm
                                    </Button>
                                }
                            </Box>
                            {
                                t === timeSelected && 
                                <ButtonTimeZone tz={tz} timeSelected={timeSelected} timezoneSelected={timezoneSelected} setTimezoneSelected={setTimezoneSelected}/>
                            }
                        </>
                    )
                })
            }
        </Box>
    )
}

