const timeDisplayArray = {
    "09:00": "9:00am", "09:30": "9:30am",
    "10:00": "10:00am", "10:30": "10:30am",
    "11:00": "11:00am", "11:30": "11:30am",
    "12:00": "12:00pm", "12:30": "12:30pm",
    "13:00": "1:00pm", "13:30": "1:30pm",
    "14:00": "2:00pm", "14:30": "2:30pm",
    "15:00": "3:00pm", "15:30": "3:30pm",
    "16:00": "4:00pm", "16:30": "4:30pm",
    "17:00": "5:00pm", "17:30": "5:30pm",
    "18:00": "6:00pm", "18:30": "6:30pm",
    "19:00": "7:00pm",
}

const statesList = [
    'AL', 'AZ', 'AR', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI','ID', 
    'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI',
    'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NC',
    'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

const constant = {timeDisplayArray,statesList}
export default constant