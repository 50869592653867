const contactForm = (form) => {
    if(form.values.first_name === ""){ form.errors.first_name = "Required" }
    else form.errors.first_name = null

    if(form.values.last_name === ""){ form.errors.last_name = "Required" }
    else { form.errors.last_name = null }

    if(form.values.email === ""){ form.errors.email = "Required" }
    else if(!validateEmail(form.values.email)){ form.errors.email = "Invalid Email Address" }
    else { form.errors.email = null}

    form.errors.phone = validatePhone(form.values.phone) 

    if(form.values.subject === ""){ form.errors.subject = "Required" }
    else { form.errors.subject = null}

    if(form.values.message === ""){ form.errors.message = "Required" }
    else { form.errors.message = null}

    form.validation = (form.errors.first_name === null && 
        form.errors.last_name === null && 
        form.errors.email === null && 
        form.errors.phone === null &&
        form.errors.subject === null &&
        form.errors.message === null)
    
    return form
}

const validateEmail = (input) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
        return true
    } else {
        return false
    }
}

const validatePhone = (input) => {
    input = input.replace(/\D/g, '');
    input = input.substring(0, 10);

    var size = input.length;
    if (size === 0) { return "Phone is required" } 
    else if (size < 10) { return "Please fill valid Phone number" }
    else { return null }
};

const validation = {
    contactForm
};

export default validation;