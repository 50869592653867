const SiteConstant = {
    "localhost": {
        title: "AAPFIRSTRESPONDERS",
        logo: "/images/logos/2.svg"
    }, 

    "firstresponder.soheard.dev": {
        title: "AAPFIRSTRESPONDERS",
        logo: "/images/logos/2.svg"
    },

    "aapfirstresponders.com": {
        title: "AAPFIRSTRESPONDERS",
        logo: "/images/logos/2.svg"
    },
}

export default SiteConstant;