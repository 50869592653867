import React, { Fragment } from 'react';
import Footer from '../components/Footer';
import HeaderSection from '../components/Header';

function Home(props) {
    return (
        <Fragment>
            <HeaderSection />
            <div className="page-content-wrapper mt-0 overflow-hidden">
                <section className="hero-banner mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex align-items-center h-100 banner-left-text">
                                    <div className="banner-content">
                                        <img className="img-fluid mob-section-img-desktop-hide" src="./images/hero-img-2-new.png" alt="" />
                                        <h1 className='theme-red-text home-banner-title'>
                                            Ask Questions with a <br />
                                            <span className='theme-red-text'>Licensed Professional</span>
                                        </h1>
                                        <p className='text-left'>With a Best Interest or Fiduciary Responsibility</p>
                                        <a href="/setup-an-appointment" className="btn btn-pri">Schedule A Free Consultation</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img className="img-fluid mob-hero-hide" src="./images/hero-img-2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header_style">
                                <h1 className='d-flex flex-column align-items-center'>Ask Questions About<span className='heading-underline-style mt-2'></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="cards-wrapper">
                        <div className="row justify-content-between">
                            <div className="col-md-2 col-6">
                                <a href="/setup-an-appointment" className='d-flex flex-column align-items-center'>
                                    <div className="suspendisse_card" style={{ minheight: '200px' }}>
                                        <span><img width={"80px"} src="./images/1-new.png" alt="" /></span>
                                    </div>
                                    <h2 className='mb-0 ask-question-title'>Pension</h2>
                                </a>
                            </div>
                            <div className="col-md-2 col-6">
                                <a href="/setup-an-appointment" className='d-flex flex-column align-items-center'>
                                    <div className="suspendisse_card" style={{ minheight: '200px' }}>
                                        <span><img width={"76px"} src="./images/2-new.png" alt="" /></span>
                                    </div>
                                        <h2 className='mb-0 ask-question-title'>Retirement</h2>
                                </a>
                            </div>
                            <div className="col-md-2 col-6">
                                <a href="/setup-an-appointment" className='d-flex flex-column align-items-center'><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"83px"} src="./images/3-new.png" alt="" /></span>
                                </div>
                                    <h2 className='mb-0 ask-question-title'>Long Term Care</h2>
                                </a>
                            </div>
                            <div className="col-md-2 col-6">
                                <a href="/setup-an-appointment" className='d-flex flex-column align-items-center'><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"82px"} src="./images/4-new.png" alt="" /></span>
                                    
                                </div>
                                    <h2 className='mb-0 ask-question-title'>Disability</h2>
                                </a>
                            </div>
                            <div className="col-md-2 col-6">
                                <a href="/setup-an-appointment" className='d-flex flex-column align-items-center'><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"63px"} src="./images/5-new.png" alt="" /></span>
                                    
                                </div>
                                    <h2 className='mb-0 ask-question-title'>Life Insurance</h2>
                                </a>
                            </div>
                            <div className="col-md-2 col-6">
                                <a href="/setup-an-appointment" className='d-flex flex-column align-items-center'><div className="suspendisse_card" style={{ minheight: '200px' }}>
                                    <span><img width={"80px"} src="./images/6-new.png" alt="" /></span>
                                </div>
                                    <h2 className='mb-0 ask-question-title'>Tax-Free Income</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="our_current_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header_style">
                                    <h1 className='d-flex flex-column align-items-center'>Our current service area includes <br /> the following states<span className='heading-underline-style mt-2'></span></h1>
                                    {/* <p className="tittel-sub-tittel">
                                        We plan to rapidly expand our service area in 2023.
                                    </p> */}
                                </div>
                                <div className="map_section text-center">
                                    <img src="./images/map.png?v=0.1" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section-four">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                            <div className="header_style text-left">
                                <h1>
                                    Ready to Get Started?
                                </h1>
                                </div>

                                <p className='d-flex flex-column responsive-align'>Schedule A Free Consultation. <span className='heading-underline-style mt-2 mb-3'></span></p>
                                <a href="/setup-an-appointment" className="btn btn-pri align-items-flex-start">Book Now</a>
                            </div>
                            <div className="col-md-6">
                                <div className="img-wrapper">
                                    <img className="img-fluid" src="./images/happened-img-home.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Home;