import React from 'react'
import './style.css';

export default function index({logo = true, className = ""}) {
    return (
        <div className={`position-absolute w-100 px-0 px-md-5 ${className}`} style={{zIndex:9999}}>
            <div className='row px-0 px-md-5'>
                <div className='col-6'>
                    {
                        logo 
                        ? <img src="./images/brand-logo-1.svg" alt="main_logo" width={"300px"} className='logo-responsive'/>
                        : <div></div>
                    }
                </div>
                <div className='col-6'>
                    <div className=' header-right-container'>
                            <svg  className='nav-right-bg' xmlns="http://www.w3.org/2000/svg" width="1000" height="55" viewBox="0 0 1000 55" fill="none">
                            <path d="M0 0H1010V55C1010 55 330.999 55 165.499 55C-0.000289916 55 0 0 0 0Z" fill="#F7E0E1"/>
                            <path d="M0.00012207 0H1010V43C1010 43 351.5 43 184 43C16.5 43 0.00012207 0 0.00012207 0Z" fill="#CE2029"/>
                            </svg>

                            <svg  className='nav-righ-mobile' width="1000" height="266" viewBox="0 0 1000 266" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H1010V266C1010 266 330.999 266 165.499 266C-0.000289916 266 0 0 0 0Z" fill="#F7E0E1"/>
                            <path d="M0.00012207 0H1010V207.964C1010 207.964 351.5 207.964 184 207.964C16.5 207.964 0.00012207 0 0.00012207 0Z" fill="#CE2029"/>
                            </svg>

                            <p className='header-right-text'>
                                <svg className='responsive-svg' xmlns="http://www.w3.org/2000/svg" color='white' viewBox="0 0 24 24" fill="currentColor" width={24}>
                                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                </svg> support@aapfirstresponders.com
                            </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
